.gridContainer {
  padding: 20px;
  max-width: 1140px;
  margin: 0 auto;
}
@media screen and (max-width: 480px) {
  .gridContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .gridContainer > div {
    padding: 15px 0;
  }
  .skills-h {
    font-size: 29px;
    font-weight: 700;
  }
  .skills-para {
    font-size: 16px;
    color: #c4b8d7;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .gridContainer > div {
    padding: 30px 0;
  }
  .skills-h > h2 {
    font-size: 38px;
    font-weight: 700;
  }
  .skills-para > p {
    font-size: 18px;
    color: #c4b8d7;
  }
}

@media screen and (min-width: 768px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
  .gridContainer > div {
    padding: 30px 0;
  }
  .skills-h > h2 {
    font-size: 40px;
    font-weight: 700;
  }
  .skills-para > p {
    font-size: 18px;
    color: #c4b8d7;
  }
}

/* Tooltip arrow */
[role="tooltip"]::before {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px 8px 0 8px;
  border-style: solid;
  border-color: rgb(17, 24, 39) transparent transparent transparent;
}

/* Focus styles */
.group h2:focus-visible {
  outline: 2px solid #845ec2;
  outline-offset: 2px;
  border-radius: 2px;
}

/* Ensure tooltip stays visible when moving to it */
.group:hover [role="tooltip"],
.group:focus-within [role="tooltip"] {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

/* Animation for tooltip */
[role="tooltip"] {
  transition: visibility 0.2s ease, opacity 0.2s ease;
  pointer-events: none;
}

/* Ensure tooltip container takes full width but maintains center alignment */
.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  [role="tooltip"] {
    width: 90vw;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
  }
}
