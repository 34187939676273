.footer {
  background: #242424;
  /* border-width: auto; */
}
.footer > div {
  max-width: 1140px;
  margin: 0 auto;
}
.foot-icon > li {
  list-style: none;
  cursor: pointer;
}
.foot-icon {
  display: flex;
}

@media screen and (max-width: 480px) {
  .footer > div {
    display: flex;
    flex-direction: column;
    padding: 20px 50px;
    align-items: center;
  }
  .foot-icon > li {
    padding: 19px 14px;
  }
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .footer > div {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .footer > div > h1 {
    padding: 20px;
  }
  .foot-icon {
    gap: 15px;
    padding: 20px;
    justify-content: end;
  }
}

@media screen and (min-width: 768px) {
  .footer > div {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .footer > div > h1 {
    padding: 20px;
    font-size: 40px;
  }
  .foot-icon {
    gap: 20px;
    padding: 20px;
    justify-content: end;
  }
}
.foot-icon > li  a:hover{
background: purple;
border-radius: 100%;
color: rgb(82, 32, 82);
}