.fairy-dust {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 768px) {
  .fairy-dust-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  @keyframes floatParticle {
    0% {
      transform: translateY(0) translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100px) translateX(var(--x-offset));
      opacity: 0;
    }
  }

  .fairy-dust {
    width: 4px;
    height: 4px;
    background: radial-gradient(circle, rgba(132, 94, 194, 0.8), transparent);
    border-radius: 50%;
    animation: floatParticle 3s ease-out infinite;
  }

  .nav-icon a {
    position: relative;
  }

  .nav-icon a::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: radial-gradient(circle, rgba(132, 94, 194, 0.4), transparent);
    border-radius: 50%;
    pointer-events: none;
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s ease;
  }

  .nav-icon a:hover::after {
    opacity: 1;
    transform: scale(1);
    animation: pulse 1.5s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.4;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.2;
    }
    100% {
      transform: scale(1);
      opacity: 0.4;
    }
  }
}
