.mainPages {
  background: #121212;
  max-width: 1140px;
  margin: 0 auto;
}
.underLine {
  border-bottom: 2px solid #845ec2;
  padding: 0 0 8px 0;
}

.btn {
  font-size: 0.8125em;
  cursor: pointer;
  padding: 16px 32px;
  background: transparent;
  text-decoration: none;
  font-size: 0.98rem;
  letter-spacing: 0.3rem;
}

.btn:hover {
  color: #F0F0F0;
  padding: 10px;
  border: 2px solid #c5501e;
}
.btn:focus {
  border: 2px solid #c5501e;
  padding: 10px;
  background: #d4d4d446;
}
.btn:active {
  border: 2px solid #887b9e;
  padding: 0 0 10px 0;
}
.btn:disabled {
  border: 3px solid #845ec2;
  padding: 0 0 10px 0;
}

@media screen and (max-width: 480px) {
  .mainPages {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .houseImg {
    height: 350px;
    width: 250px;
    background-image: 
      url('../assets/bryantemple.png');
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);
  }
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .home-h > h2 {
    /* padding: 20px; */
    max-width: 100%;
    margin: 0 auto;

    text-align: center;
    letter-spacing: 0.3rem;
  }
  .txtHp {
    padding: 20px;
  }
  .text-para {
    max-width: 100%;
    font-size: 16px;
    text-align: left;
  }
  .text-para > p {
    color: rgb(177, 177, 177);

    /* top | right | bottom | left */
    padding: 20px 0 20px 10px;
  }
}

/*Tablet View */

@media screen and (min-width: 480px) and (max-width: 768px) {
  .mainPages {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .houseImg {
    height: 350px;
    width: 100%;
    background-image: 
      url('../assets/bryantemple.png');
    background-size: cover;
    filter: grayscale(100%);
  }
  .text-container {
    padding: 20px;
  }
  .home-h {
    font-size: 55px;
    /* padding: 0 0 30px 0; */
    max-width: 100%;
  }
  .text-para {
    max-width: 100%;
  }
  .text-para > p {
    /* top and bottom | left and right */
    padding: 25px 0;
    color: rgb(177, 177, 177);
  }
  .underLine {
    font-size: 2rem;
  }
}

/* Large Screen View */

@media screen and (min-width: 768px) {
  .mainPages {
    /* background: #845ec2; */
    /* max-width: 1240px;
    margin: 0 auto; */
    /* padding: 0 20px; */
    display: grid;
    grid-template-columns: 2fr 1fr;
    position: relative;
  }
  .houseImg {
    height: 500px;

    background-image: 
      url('../assets/bryantemple.png');
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);
  }
  .text-container {
    padding: 10px 20px;
  }

  .txtHp {
    padding: 20px 0;
  }

  .home-h {
    /* top | right | bottom | left */
    padding: 0 0 20px 0;
    max-width: 100%;
  }

  .text-para {
    width: 100%;
  }
  .text-para > p {
    color: rgb(177, 177, 177);
    padding: 29px 0;
  }
}

/* Tooltip styles */
.group:focus {
  outline: none;
  text-decoration: underline dotted;
  text-underline-offset: 2px;
}

/* For users who prefer reduced motion */
@media (prefers-reduced-motion: reduce) {
  .group span {
    transition: none;
  }
}

/* Ensure tooltip is accessible via keyboard */
.group:focus span {
  visibility: visible;
}

.group:hover,
.group:focus {
  outline: none;
}

.group:focus-visible {
  outline: 2px solid #845ec2;
  outline-offset: 2px;
  border-radius: 2px;
}

[role="tooltip"] {
  transition: visibility 0.2s ease;
}
