@media screen and (max-width: 480px) {
  .ty,
  .tm {
    width: 90%;
    margin: 20px;
  }
  .fline > hr {
    max-width: 90%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .tm {
    display: none;
  }
  .ty {
    width: 90%;
    margin: 20px;
  }
  .fline > hr {
    max-width: 95%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 768px) {
  .ty {
    max-width: 95%;
    margin: 0 auto;
    padding: 30px 0;
  }
  .tm {
    display: none;
  }
  .fline > hr{
    max-width: 95%;
    margin: 0 auto;
  }
}
.fline {
  background: #242424;
  padding: 20px 0;
}
