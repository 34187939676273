@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
/* Base Style */
* {
  margin: 0;
  font-family: 'Space Grotesk';
  padding: 0;
  color: #e1e1e1;
}
/* body{
  background: #121212;
} */
:root {
  --primary-color: #333;
  --secondary-color: #d7d7d7;
  --background-color: #dddddd;
}

[data-theme="dark"] {
  --primary-color: #d7d7d7;;
  --secondary-color: #333;
  --background-color: #262626;
}
